<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">车辆基础信息</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleSync" icon="sync" type="primary">同步车辆</a-button>
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary">新增
      </a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="车辆名称">
              <a-input v-model="searchParams.fullName" placeholder="请输入" allowClear/>
            </a-form-item>
            <a-form-item label="车牌号码">
              <a-input v-model="searchParams.platNumber" placeholder="请输入" allowClear/>
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                allowClear
                placeholder="请选择"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
        :scroll="{x:1240}">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="useType" slot-scope="text, record">
          <a-tag v-if="record.useType === 'OTHER'">其他</a-tag>
          <a-tag v-if="record.useType === 'XJ'" color="#87d068">巡检</a-tag>
          <a-tag v-if="record.useType === 'QY'" color="#108ee9">清运</a-tag>
        </template>
        <template slot="volume" slot-scope="text, record">
          <div v-if="record.useType === 'OTHER'">其他</div>
          <div v-if="record.useType === 'XJ'">~</div>
          <div v-if="record.useType === 'QY' && record.volume!==null ">{{ record.volume}}吨</div>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleHistory(record.id)">清运历史</a>
          <a-divider type="vertical"/>
          <a @click="handleEdit(record.id)" @click.stop>编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)" @click.stop>删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="新增车辆" v-model="addModal" width="1160px">
      <car-info-form ref="addRef" @addSuccess="addSuccess" @addError="addError"></car-info-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭
        </a-button>
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改车辆"
      width="1160px"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading">
      <car-info-form ref="editRef" @editSuccess="editSuccess" @editError="editError"></car-info-form>
    </a-modal>
    <a-drawer
      title="车辆详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :mask="false"
      width="450px"
      :visible="detailDrawer">
      <car-info-detail ref="viewRef"></car-info-detail>
    </a-drawer>
    <!--车辆清运任务历史Model-->
    <a-drawer
      title="车辆历史清运任务"
      placement="right"
      :closable="true"
      @close="closeHistoryModal"
      :mask="false"
      :drawerStyle="{background: '#f0f2f5'}"
      width="1600px"
      :visible="historyModal">
      <history-list ref="historyRef"></history-list>
    </a-drawer>
    <!--<a-modal title="历史任务" :width="1400" :footer="null" :visible="historyModal" @cancel="closeHistoryModal">
      <history-list ref="historyRef"></history-list>
    </a-modal>-->
    <!--同步窗口-->
    <a-modal
      title="同步权限下所有车辆"
      :visible="syncModal"
      :maskClosable="false"
      @ok="handleSyncOk"
      @cancel="closeSyncModal">
      <sync-form ref="syncRef" @syncSuccess="syncSuccess" @syncError="syncError"></sync-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import entityCRUDWithCopy from '../../../common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { carInfoColumns, statusList } from './common/common'
  import CarInfoForm from './Form'
  import HistoryList from './HistoryList'
  import SyncForm from './SyncForm'
  import CarInfoDetail from './Detail'

  export default {
    name: 'carInfoList',
    mixins: [entityCRUDWithCopy],
    components: { PageLayout, CarInfoForm, HistoryList, CarInfoDetail, SyncForm },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carInfoApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          fullName: '',
          platNumber: null,
          departmentId: null,
          macId: ''
        },
        initColumns: carInfoColumns(),
        orgTree: [],
        statusList: statusList(),
        historyModal: false, //历史任务弹出框
        syncModal: false //同步信息弹出框
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              this.handleView(record.id)
            }
          }
        }
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleHistory (id) {
        this.historyModal = true
        this.$nextTick(() => {
          this.$refs.historyRef.searchRecord(id)
        })
      },
      closeHistoryModal () {
        this.historyModal = false
        this.$nextTick(() => {
          this.$refs.historyRef.resetData()
        })
      },
      /*同步信息*/
      handleSync () {
        this.syncModal = true
      },
      syncSuccess () {
        this.syncModal = false
        this.search()
      },
      closeSyncModal () {
        this.syncModal = false
        this.search()
      },
      syncError () {
        this.syncModal = false
      },
      handleSyncOk () {
        this.$refs.syncRef.submitSync()
      }
    }
  }
</script>

<style scoped></style>

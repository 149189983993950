<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-row>
        <a-col :span="12">
          <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-tree-select
              v-decorator="['department.id', { rules: [{ required: true, message: '所属部门不能为空' }] }]"
              :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
              :treeData="orgTree"
              allowClear
              placeholder="请选择，默认当前登录用户所在部门"
            ></a-tree-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="用途" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-radio-group
              v-decorator="['useType', {initialValue:'QY', rules: [{ required: true, message: '请选择故障类型' }] }]"
              defaultValue="QY" @change="handleTypeChange">
              <a-radio value="QY">
                清运
              </a-radio>
              <a-radio value="XJ">
                巡检
              </a-radio>
              <a-radio value="OTHER">
                其他
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item v-if="useType === 'QY' " label="车辆载重" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input-search v-decorator="['volume']" placeholder="请输入清运车辆载重">
              <a-button slot="enterButton">
                吨
              </a-button>
            </a-input-search>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车辆名称" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['fullName']" placeholder="请输入车辆名称"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="设备ID" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['objectId']" placeholder="请输入设备ID"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车牌号码" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input
              v-decorator="['platNumber', { rules: [{ required: true, message: '车牌号码不能为空' }] }]"
              placeholder="请输入车牌号码"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="设备号码" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['macId']" placeholder="请输入设备号码"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="设备型号" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['macName']" placeholder="请输入设备型号"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="购买时间" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-date-picker
              style="width: 100%;"
              :showTime="{ format: 'YYYY-MM-DD' }"
              format="YYYY-MM-DD"
              v-decorator="['buyDate', { rules: [{ required: true, message: '请选择购买时间' }] }]"
              placeholder="请选择日期"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="到期时间" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-date-picker
              style="width: 100%;"
              :showTime="{ format: 'YYYY-MM-DD' }"
              format="YYYY-MM-DD"
              v-decorator="['blockDate', { rules: [{ required: true, message: '请选择到期时间' }] }]"
              placeholder="请选择日期"
            ></a-date-picker>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="sim卡号码" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['sim']" placeholder="请输入sim卡号码"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="排序号" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['sorter']" placeholder="请输入排序号"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车架号" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-input v-decorator="['frameNumber']" placeholder="请输入车架号"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!--      <a-form-item label="任务状态"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select allowClear
                  placeholder="请选择任务状态"
                  v-decorator="['status',{rules: [{ required: true, message: '任务状态不能为空' }]}]">
          <a-select-option v-for="item in statusList"
                           :key="item.value"
                           :value="item.value"
                           :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>-->
      <a-row>
        <a-col :span="12">
          <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
            <qiniu-image-upload
              ref="qiniuImageRef"
              @getImageData="getImageData"
              :document-dir-id="31"
              :multiSelection="true"
              type="edit"
              :items="formItem.images"
            ></qiniu-image-upload>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-spin>
</template>

<script>
  import { carInfoFormVO } from './common/carInfoFormVO'
  import entityCURDCallback from '../../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import { statusList } from './common/common'
  import moment from 'moment'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'CarInfoForm',
    mixins: [entityCURDCallback],
    components: { QiniuImageUpload },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carInfoApi,
        initFormItem: carInfoFormVO(),
        showFormItem: false,
        statusList: statusList(),
        orgTree: [],
        areaTree: [],
        useType: 'QY'
      }
    },
    computed: {
      selfDepts () {
        return this.currentDeptId().organizationId + ''
      },
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.getOrgTree()
      this.form.setFieldsValue({
        'department.id': this.selfDepts + ''
      })
      this.setDeptId()
    },
    methods: {
      handleTypeChange (e) {
        this.useType = e.target.value
      },
      setDeptId () {
        let deptId = null
        // console.log(this.currentUser)
        // 获取当前部门
        deptId = this.currentDeptId().organizationId + ''
        if (deptId) {
          this.form.setFieldsValue({
            'department.id': deptId
          })
        }
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        this.form.setFieldsValue({
          objectId: this.formItem.carInfo.objectId,
          volume: this.formItem.carInfo.volume,
          platNumber: this.formItem.carInfo.platNumber,
          fullName: this.formItem.carInfo.fullName,
          'department.id': this.formItem.carInfo.department.id + '',
          macId: this.formItem.carInfo.macId,
          macName: this.formItem.carInfo.macName,
          useType: this.formItem.carInfo.useType,
          blockDate: moment(this.formItem.carInfo.blockDate),
          buyDate: moment(this.formItem.carInfo.buyDate),
          sim: this.formItem.carInfo.sim,
          sorter: this.formItem.carInfo.sorter,
          frameNumber: this.formItem.carInfo.frameNumber
        })
      },
      setVOFields (values) {
        this.formItem.carInfo.objectId = values.objectId
        this.formItem.carInfo.platNumber = values.platNumber
        this.formItem.carInfo.fullName = values.fullName
        this.formItem.carInfo.department.id = values.department.id
        this.formItem.carInfo.macId = values.macId
        this.formItem.carInfo.macName = values.macName
        this.formItem.carInfo.blockDate = values.blockDate
        this.formItem.carInfo.buyDate = values.buyDate
        this.formItem.carInfo.volume = values.volume
        this.formItem.carInfo.useType = values.useType
        this.formItem.carInfo.sim = values.sim
        this.formItem.carInfo.sorter = values.sorter
        this.formItem.carInfo.frameNumber = values.frameNumber
      },
      addSuccessCallback (data) {
        this.form.setFieldsValue({
          'department.id': this.selfDepts + ''
        })
        // 添加成功之后Form内部可做的回调
      },
      addErrorCallback (e) {
        // 添加失败之后Form内部可做的回调
        // this.form.resetFields()
      },
      editSuccessCallback (data) {
        // 修改成功之后Form内部可做的回调
      },
      editErrorCallback (e) {
        // 修改失败之后Form内部可做的回调
        // this.form.resetFields()
      },
      //////////////////////////////////////////////////////
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>

<style scoped></style>

<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="新增车所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['departmentId',{rules: [{ required: true, message: '部门不能为空' }]}]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          placeholder="请选择部门"
        ></a-tree-select>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'Form',
    data () {
      return {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
        orgTree: [],
        form: this.$form.createForm(this),
        spinShow: false
      }
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      submitSync () {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.$http(this, {
              url: SERVICE_URLS.csgz.carInfoApi.synchronousDevices,
              data: { deptId: Number(values.departmentId) },
              successTipsContent: '同步设备信息成功!',
              success: (data) => {
                this.$emit('syncSuccess')
                this.form.resetFields()
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('syncError')
                this.form.resetFields()
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      }
    }
  }
</script>